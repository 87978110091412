import React, { Component } from 'react'
import styled from 'styled-components'

const ChangeingTextBlock = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .changing-top-text {
        font-size: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        min-height: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        color: #aaaaaa;
        font-family: HarabaraMais-Bold;
        line-height: 1;
        margin-bottom: calc(20px + (75 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        @media (max-width: 1024px) {
            font-size: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            min-height: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            margin-bottom: calc(20px + (75 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        }
        @media (max-width: 768px) {
            font-size: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            min-height: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            margin-bottom: calc(20px + (75 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        }
    }
    .text-cursor {
        font-size: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        min-height: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        margin-bottom: calc(20px + (75 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        margin-left: 5px;
        width: 2px;
        position: relative;
        background: #aaaaaa;
        opacity: 0;
        line-height: 1;
        animation: fade-in 1s infinite ease-in-out both;
        @media (max-width: 1024px) {
            font-size: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            min-height: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            margin-bottom: calc(20px + (75 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        }
        @media (max-width: 768px) {
            font-size: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            min-height: calc(22px + (45 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
            margin-bottom: calc(20px + (75 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        }
    }
`;

export default class FullPageCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topSentanceArray: ['Simplifying the Business of', 'Scripting the Narrative of'],
            currentTextIndex: 0,
            charAtIndex: 0,
            typingSpeed: 100
        }
    }

    componentDidMount() {
        this.addTypeText();
    }

    addTypeText() {
        const {
            topSentanceArray,
            currentTextIndex,
            charAtIndex,
            typingSpeed
        } = this.state;
        var currentText = topSentanceArray[currentTextIndex];
        if (charAtIndex < currentText.length) {
            var elem = document.getElementsByClassName('changing-top-text')[0];
            if(elem) {
                var innerHTML = elem.innerHTML;
                elem.innerHTML = innerHTML + currentText.charAt(charAtIndex);
                this.setState((state) => ({
                    charAtIndex: state.charAtIndex + 1
                }), () => {
                    setTimeout(this.addTypeText.bind(this), typingSpeed);
                })
            }
        } else {
            setTimeout(this.deleteTypeText.bind(this), 2000);
        }
    }

    deleteTypeText() {
        var elem = document.getElementsByClassName('changing-top-text')[0]
        if(elem) {
            elem.style.opacity = '0';
            elem.style.transition = 'all 450ms ease-in-out'
            setTimeout(function () {
                elem.innerHTML = '';
                elem.style.opacity = '1';
                this.setState((state) => ({
                    currentTextIndex: state.currentTextIndex + 1 > state.topSentanceArray.length - 1 ?
                        0 : state.currentTextIndex + 1,
                    charAtIndex: 0
                }), () => {
                    setTimeout(this.addTypeText.bind(this), 500);
                })
            }.bind(this), 350)
        }
    }

    render() {
        return(
            <ChangeingTextBlock className="changing-text-block">
                <p className="changing-top-text"></p>
                <p className="text-cursor"></p>
            </ChangeingTextBlock>
        );
    }
}