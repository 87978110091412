import React from 'react';
import PropTypes from 'prop-types';

import FullPageCarousel from './fullPageCarousel';
import MobileCarousel from './mobileCarousel';

function Carousel({ data, type, slidePerPage, id }) {
  return(
    <>
      {type === 'web' && slidePerPage === 'single' ?
        <FullPageCarousel dataArr={data} id={id} /> : null
      }
      {type === 'mobile' && slidePerPage === 'single' ?
        <MobileCarousel dataArr={data} id={id} /> : null
      }
    </>
  );
}

Carousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf(['web', 'mobile']),
  slidePerPage: PropTypes.oneOf(['single', 'multiple']),
  id: PropTypes.string
};

export default Carousel;
