import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MovableBlock = styled('div')`
    position: absolute;
    transition: all 5000ms ease-in-out;
`;

export default class Movable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
    this.moveInterval = null;
    this._moveBlock = React.createRef();
    this.handleOnScroll = this.handleOnScroll.bind(this);
  }

  componentDidMount() {
    const elem = this._moveBlock.current;
    const newPos = this.makeNewPosition(elem);
    const rotation = Math.floor(Math.random() * 360);
    const self = this;
    elem.style.top = newPos[0] + 'px';
    elem.style.left = newPos[1] + 'px';
    elem.style.transform = 'rotate(' + rotation + 'deg)';
    elem.style.right = 'initial';
    elem.style.bottom = 'initial';
    this.animateDiv();
    this.handleOnScroll();
    window.addEventListener('scroll', self.handleOnScroll);
  }

  componentWillUnmount() {
    const self = this;
    window.removeEventListener('scroll', self.handleOnScroll);
  }

  handleOnScroll() {
    const { elemId } = this.props;
    const { active } = this.state;
    const elem = document.getElementById(elemId);
    if(elem) {
      const elemScrollTop = elem.getBoundingClientRect().top;
      const calcTop = Math.abs(elemScrollTop - window.innerHeight);
      if(calcTop > (window.innerHeight / 3) && calcTop < (window.innerHeight * 1.5)) {
        if(!active) {
          this.setState({
            active: true
          }, () => {
            this.animateDiv();
          });
        }
      } else {
        this.setState({
          active: false
        }, () => {
          clearInterval(this.moveInterval);
          this.moveInterval = null;
        });
      }
    }
  }

  animateDiv() {
    const { active } = this.state;
    const self = this;
    if(active) {
      this.moveInterval = setInterval(function () {
        const elem = self._moveBlock.current;
        const newPos = self.makeNewPosition(elem);
        const rotation = Math.floor(Math.random() * 360);
        elem.style.top = newPos[0] + 'px';
        elem.style.left = newPos[1] + 'px';
        elem.style.right = 'initial';
        elem.style.bottom = 'initial';
        elem.style.transform = 'rotate(' + rotation + 'deg)';
      }, 5000);
    } else {
      clearInterval(this.moveInterval);
      this.moveInterval = null;
    }
  }

  makeNewPosition(elem){
    // Get viewport dimensions (remove the dimension of the div)
    if(typeof window !== undefined && elem) {
      const h = window.innerHeight - elem.offsetHeight;
      const w = window.innerWidth - elem.offsetWidth;
      const nh = Math.floor(Math.random() * h);
      const nw = Math.floor(Math.random() * w);
      return [nh,nw];
    }
  }

  render() {
    const { children, style } = this.props;

    return(
      <MovableBlock
        style={style}
        ref = {this._moveBlock}
      >{children}</MovableBlock>
    );
  }
}

Movable.propTypes = {
  elemId: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired
};
