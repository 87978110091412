import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Icon from '../icon';

const CarouselContainer = styled.div`
    background: #f1526f;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display:none;
    @media(max-width: 768px) {
        display:block;
    }
    .section-top, .section-bottom {
        position: relative;
        width: 100%;
        display: block;
        overflow: hidden;
        .carousel-block {
            position: absolute;
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            .icon-block {
                transition: opacity 450ms cubic-bezier(.02, .01, .47, 1);
            }
            .details-block .heading span {
                opacity: 0;
            }
            .details-block .sub-heading, .details-block .description {
                transition: transform 550ms cubic-bezier(.02, .01, .47, 1), opacity 150ms cubic-bezier(.02, .01, .47, 1);
                opacity: 0;
            }
            .carousal-image {
                transition: all 750ms cubic-bezier(.02, .01, .47, 1);
                opacity: 0;
            }
            &.active {
                .icon-block {
                    opacity: 1 !important;
                    z-index: 10;
                }
                .details-block .sub-heading, .details-block .description {
                    transform: translate(0,0);
                    opacity: 1;
                    z-index: 10;
                }
                .carousal-image {
                    transform: translate(-50%,-50%) scale(1);
                    opacity: 1;
                    z-index: 10;
                }
            }
            &.prev {
                .details-block .heading span {
                    transform: translate(0,-100%);
                }
                .details-block .sub-heading, .details-block .description, .carousal-image {
                    transform: translate(0,-60%);
                }
                .carousal-image {
                    transform: translate(-50%,-150%) scale(1.5);
                }
            }
            &.next {
                .details-block .heading span {
                    transform: translate(0,100%);
                }
                .details-block .sub-heading, .details-block .description, .carousal-image {
                    transform: translate(0,60%);
                }
                .carousal-image {
                    transform: translate(-50%,50%) scale(1.5);
                }
            }
            .carousal-image {
                position: absolute;
                width: auto;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .section-bottom {
        height: 60%;
        .carousel-data-block {
            width: 100%;
            height: 100%;
            position: relative;
        }
        .navigation-block {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 11;
            width: 100%;
            .navigation {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;
                .navigation-button {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #ffffff;
                    border-radius: 100%;
                    position: relative;
                    transition: all 250ms ease-in-out;
                    cursor: pointer;
                    svg path {
                        transition: all 250ms ease-in-out;
                    }
                    &:hover {
                        border: 1px solid #f1526f;
                        svg path {
                            fill: #f1526f;
                        }
                    }
                    i {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        line-height: 0.5;
                    }
                }
            }
        }
    }
    .section-top {
        height: 40%;
        padding: 20px;
        .carousel-data-block {
            width: 100%;
            height: 100%;
            position: relative;
            .carousel-block {
                .icon-block {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    background-color: rgba(255, 255, 255, 0.1);
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    opacity: 0;
                }
                .details-block {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    .heading {
                        font-size: 40px;
                        font-family: HarabaraMais-Bold;
                        color: #fff;
                        margin-bottom: 5px;
                        line-height: 1.1;
                        overflow: hidden;
                        font-weight: normal;
                        span {
                            display: inline-block;
                        }
                    }
                    .sub-heading {
                        color: #000;
                        font-family: SFUIText-Medium;
                        font-size: 18px;
                        font-weight: bolder;
                        margin-bottom: 15px;
                    }
                    .description {
                        color: #ffffff;
                        font-size: 14px;
                        font-family: SFUIText-Medium;
                    }
                }
            }
        }
    }
`;

export default class MobileCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselData: [],
    };
    this.carouselInterval = null;
  }

  componentDidMount() {
    const { dataArr, id } = this.props;
    dataArr.map((data, index) => {
      const carousel = { ...data};
      let className = 'carousel-block ' + data.icon;
      if(index < 1) {
        className += ' active';
      } else if(index === 1) {
        className += ' next';
      } else if(index === dataArr.length - 1) {
        className += ' prev';
      }
      carousel.className = className;

      this.setState((state) => ({
        carouselData: [...state.carouselData, carousel]
      }));
      return data;
    });
    setTimeout(() => {
      const activeHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.active .details-block .heading span`));
      activeHeadingArr.forEach((elem, index) => {
        elem.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
        elem.style.transform = 'transform: translate(0, 0);';
        elem.style.opacity = '1';
      });
      this.playCarousel();
    }, 0);
  }

  componentWillUnmount() {
    console.log('Helloooo');
    clearInterval(this.carouselInterval);
    this.carouselInterval = null;
  }

  playCarousel(){
    if(!this.carouselInterval) {
      const self = this;
      this.carouselInterval = setInterval(function () {
        self.changeSlide('right', 'auto');
      }, 10000);
    }
  }

  changeSlide(position, type) {
    const { id } = this.props;
    if(type === 'manual') {
      clearInterval(this.carouselInterval);
      this.carouselInterval = null;
      this.playCarousel();
    }
    const topSection = this._topSectionRef;
    const bottomSection = this._bottomSectionRef;
    if(topSection && bottomSection) {
      const topChildsArr = Array.from(topSection.children[0].children);
      const bottomChildsArr = Array.from(bottomSection.children[0].children);
      const prevIndex = topChildsArr.findIndex((elem) => elem.classList.contains('prev'));
      const nextIndex = topChildsArr.findIndex((elem) => elem.classList.contains('next'));
      const activeIndex = topChildsArr.findIndex((elem) => elem.classList.contains('active'));
      const activeHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.active .details-block .heading .span-block`));
      let prevHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.prev .details-block .heading .span-block`));
      let nextHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.next .details-block .heading .span-block`));
      if(position === 'right') {
        activeHeadingArr.forEach((elem) => {
          const spanChildArr = Array.from(elem.children);
          spanChildArr.forEach((span, index) => {
            span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
            span.style.transform = 'transform: translate(0px, -100%)';
            span.style.opacity = '0';
          });
        });
        nextHeadingArr.forEach((elem) => {
          const spanChildArr = Array.from(elem.children);
          spanChildArr.forEach((span, index) => {
            span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
            span.style.transform = 'transform: translate(0, 0);';
            span.style.opacity = '1';
          });
        });
        setTimeout(() => {
          const upcomingNextIndex = nextIndex + 1 > topChildsArr.length - 1 ? 0 : nextIndex + 1;
          topChildsArr[prevIndex].classList.remove('prev');
          topChildsArr[activeIndex].classList.remove('active');
          topChildsArr[activeIndex].classList.add('prev');
          topChildsArr[nextIndex].classList.add('active');
          topChildsArr[nextIndex].classList.remove('next');
          topChildsArr[upcomingNextIndex].classList.add('next');
          bottomChildsArr[prevIndex].classList.remove('prev');
          bottomChildsArr[activeIndex].classList.remove('active');
          bottomChildsArr[activeIndex].classList.add('prev');
          bottomChildsArr[nextIndex].classList.add('active');
          bottomChildsArr[nextIndex].classList.remove('next');
          bottomChildsArr[upcomingNextIndex].classList.add('next');
          nextHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.next .details-block .heading .span-block`));
          nextHeadingArr.forEach((elem) => {
            const spanChildArr = Array.from(elem.children);
            spanChildArr.forEach((span, index) => {
              span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
              span.style.transform = 'transform: translate(0, 100%)';
              span.style.opacity = '0';
            });
          });
        }, 0);
      }
      if(position === 'left') {
        activeHeadingArr.forEach((elem) => {
          const spanChildArr = Array.from(elem.children);
          spanChildArr.forEach((span, index) => {
            span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
            span.style.transform = 'transform: translate(0px, 100%)';
            span.style.opacity = '0';
          });
        });
        prevHeadingArr.forEach((elem) => {
          const spanChildArr = Array.from(elem.children);
          spanChildArr.forEach((span, index) => {
            span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
            span.style.transform = 'transform: translate(0, 0);';
            span.style.opacity = '1';
          });
        });
        setTimeout(() => {
          const upcomingPrevIndex = prevIndex - 1 < 0 ? topChildsArr.length - 1 : prevIndex - 1;
          topChildsArr[nextIndex].classList.remove('next');
          topChildsArr[activeIndex].classList.remove('active');
          topChildsArr[activeIndex].classList.add('next');
          topChildsArr[prevIndex].classList.add('active');
          topChildsArr[prevIndex].classList.remove('prev');
          topChildsArr[upcomingPrevIndex].classList.add('prev');
          bottomChildsArr[nextIndex].classList.remove('next');
          bottomChildsArr[activeIndex].classList.remove('active');
          bottomChildsArr[activeIndex].classList.add('next');
          bottomChildsArr[prevIndex].classList.add('active');
          bottomChildsArr[prevIndex].classList.remove('prev');
          bottomChildsArr[upcomingPrevIndex].classList.add('prev');
          prevHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.prev .details-block .heading .span-block`));
          prevHeadingArr.forEach((elem) => {
            const spanChildArr = Array.from(elem.children);
            spanChildArr.forEach((span, index) => {
              span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
              span.style.transform = 'transform: translate(0, -100%)';
              span.style.opacity = '0';
            });
          });
        }, 0);
      }
    }
  }

  getHeadingSpan(nameArr) {
    if(nameArr.length > 1) {
      return (
        nameArr.map((word) => {
          return (
            <div className='span-block' key={word} style={{overflow: 'hidden'}}>
              {
                word.split('').map((char, index) => <span key={char + '_span_' + index} style={!char.trim() ? { width: '12px' } : {}}>{char}</span>)
              }
            </div>
          );
        })
      );
    }
    return (
      <div className='span-block' key={nameArr[0]} style={{overflow: 'hidden'}}>
        {
          nameArr[0].split('').map((char, index) => <span key={char + '_span_' + index} style={!char.trim() ? { width: '12px' } : {}}>{char}</span>)
        }
      </div>
    );
  }

  render() {
    const {
      carouselData,
    } = this.state;
    const {
      id
    } = this.props;
    return(
      <CarouselContainer
        id={id}
      >
        <div
          className='section-top'
          ref={(topSectionRef) => {
            this._topSectionRef = topSectionRef;
          }}
        >
          <div className='carousel-data-block'>
            {carouselData.map((data) => {
              return (
                <div
                  key={data.icon}
                  className={data.className}
                >
                  <div className='icon-block'>
                    <Icon
                      name={data.icon}
                      color='#ffffff'
                      width={20}
                      height={20}
                    />
                  </div>
                  <div className='details-block'>
                    <div className='heading'>
                      {this.getHeadingSpan(data.name)}
                    </div>
                    <h2 className='sub-heading'>{data.subHeading}</h2>
                    <p className='description'>{data.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className='section-bottom'
          ref={(bottomSectionRef) => {
            this._bottomSectionRef = bottomSectionRef;
          }}
        >
          <div className='carousel-data-block'>
            {carouselData.map((data) => {
              return (
                <div
                  key={data.icon}
                  className={data.className + ' image-block'}
                >
                  <Img alt={data.icon} className='carousal-image' fluid={data.src.childImageSharp.fluid} />
                </div>
              );
            })}
          </div>
          <div className='navigation-block'>
            <div className='navigation'>
              <div
                className='navigation-button button-left'
                onClick={() => this.changeSlide('left', 'manual')}
                role="presentation"
              >
                <Icon
                  name='arrow-left'
                  color='#ffffff'
                />
              </div>
              <div
                className='navigation-button button-right'
                onClick={() => this.changeSlide('right', 'manual')}
                role="presentation"
              >
                <Icon
                  name='arrow-right'
                  color='#ffffff'
                />
              </div>
            </div>
          </div>
        </div>
      </CarouselContainer>
    );
  }
}

MobileCarousel.propTypes = {
  dataArr: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string
};
