import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Icon from '../components/icon';
import Layout from '../components/layout';
import SEOComponent from '../components/seo';
import Section from '../components/section';
import Carousel from '../components/carousel';
import TypeText from '../components/typeText';
import Movable from '../components/movable';

const BackgroundBlock = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const Moon = styled('div')`
  border-radius: 100%;
  position: absolute;
  transition: all 2000ms ease-in-out;
`;

const Star = styled('div')`
  width: 2px;
  height: 2px;
  background: #fff;
  box-shadow: 0 0 5px 1px rgba(255,255,255,0.5);
  position: absolute;
  &:after {
    content: '';
    top: 100vh;
    width: 2px;
    height: 2px;
    background: #fff;
    box-shadow: 0 0 5px 1px rgba(255,255,255,0.5);
    position: absolute;
  }
`;

const SectionContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  .section-heading {
    font-size: calc(40px + (148 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
    font-family: HarabaraMais-Bold;
    color: #f1526f;
    font-weight: normal;
    word-spacing: -10px;
    line-height: 1;
    margin-bottom: calc(12px + (18 - 12) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
    display: flex;
    justify-content: center;
  }
  .section-sub-heading {
    font-size: calc(22px + (71 - 22) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
    line-height: 1;
    letter-spacing: normal;
    font-weight: normal;
    color: #cccccc;
    font-family: SFUIText-Medium;
    margin-bottom: calc(30px + (85 - 30) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
  }
  .section-text {
    font-size: calc(18px + (50 - 18) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
    font-family: SFUIText-Regular;
    font-weight: normal;
    line-height: 1.33;
    color: #999;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: calc(30px + (60 - 30) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
  }
  .contact-btn {
    border-radius: 2px;
    cursor: pointer;
    padding: 18px calc(28px + (40 - 28) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
    text-align: center;
    font-size: 14px;
    font-family: SFUIText-Medium;
    display: inline-block;
    margin-top: 20px;
    background: #f1526f;
    color: #fff;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    position: relative;
    z-index: 0;
    &:hover {
      background:0 0;
      color: #f1526f;
    }
    &:before {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0;
      width: 100%;
      z-index: -1;
      content: '';
      background: #fff;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    }
    &:hover:before {
      top: 0;
      bottom: auto;
      height: 100%;
    }
  }
  #section-1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .background-block {
      position: absolute;
      width: 100%:
      height: 100%;
    }
    .section-text {
      font-size: calc(40px + (175 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
      line-height: 1;
      font-family: HarabaraMais-Bold;
      color: #fff;
      max-width: initial;
      font-weight: normal;
      margin-bottom: 15px;
      @media (max-width: 1024px) {
        font-size: calc(40px + (70 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320)));
      }
      @media (max-width: 768px) {
        font-size: calc(40px + (60 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (768 - 320)));
      }
    }
    .rotating-text-block {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      height: calc(40px + (175 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
      margin-bottom: 15px;
      @media (max-width: 1024px) {
        height: calc(40px + (70 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320)));
      }
      @media (max-width: 768px) {
        height: calc(40px + (60 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (768 - 320)));
      }
      .section-text {
        margin-right: 20px;
      }
      .rotating-text {
        font-size: calc(40px + (175 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        position: relative;
        line-height: 1;
        font-family: HarabaraMais-Bold;
        color: #f1526f;
        height: calc(40px + (175 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        animation: move 8s infinite;
        @media (max-width: 1024px) {
          font-size: calc(40px + (70 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320)));
          height: calc(40px + (70 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320)));
          animation: move-1024 8s infinite;
        }
        @media (max-width: 768px) {
          font-size: calc(40px + (60 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (768 - 320)));
          height: calc(40px + (60 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (768 - 320)));
          animation: move-768 8s infinite;
        }
      }
    }
  }
  #section-2, #section-3, #section-4, #section-5 {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    text-align: center;
  }

  #section-3 {
    ${Icon.Element} {
      margin-bottom: 30px;
      @media (max-width: 1024px) {
        svg {
          width: calc(180px + (200 - 180) * (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320)));
        }
      }
    }
    .section-sub-heading {
      margin-bottom: 30px;
    }
    .product-list {
      display: flex;
      justify-content: center;
      align-items: center;
      .product-block {
        margin: 0 calc(5px + (32 - 5) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        ${Icon.Element} {
          margin-bottom: 10px;
          @media (max-width: 1024px) {
            svg {
              width: 25px;
            }
          }
        }
        .product-name {
          font-size: calc(8px + (14 - 8) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
          font-family: 'SFUIText-Medium';
          color: #999999;
          text-transform: uppercase;
        }
      }
    }
    .app-download-block {
      margin-top: calc(40px + (60 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
      .download-text-block {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 450px) {
          flex-direction: column;
          .download-text {
            margin-right: 0px !important;
            margin-bottom: 10px;
          }
        }
        .download-text {
          font-size: calc(14px + (30 - 14) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
          font-family: 'SFUIText-Regular';
          color: #FFFFFF;
          line-height: 1.5;
          margin-right: 20px;
        }
        .svg-image {
          height: 40px;
        }
      }
    }
    .iso-block {
      margin-top: calc(40px + (60 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
      .iso-text {
        font-size: calc(12px + (28 - 12) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        font-family: 'SFUIText-Regular';
        color: #999999;
        line-height: 1.5;
        .iso-bold-text {
          color: #ffffff;
          font-family: 'SFUIText-Medium';
        }
      }
    }
  }
  #section-5 {
    .section-heading {
      color: #fff
    }
  }
`;

const openForm = () => {
  const form = document.getElementsByClassName('contact-us')[0];
  form.classList.remove('inactive');
  form.classList.add('active');
};

const makeNewPosition = () => {
  if(typeof window !== undefined) {
    const h = window.innerHeight - 2;
    const w = window.innerWidth - 2;
    const nh = Math.floor(Math.random() * h);
    const nw = Math.floor(Math.random() * w);
    return [nh,nw];
  }
};

const createStars = (num, type) => {
  const stars = [];
  if (typeof window !== 'undefined') {
    for(let i=0; i<num; i++) {
      const newPos = makeNewPosition();
      const top = newPos[0] + 'px';
      const left = newPos[1] + 'px';
      const width = Math.floor(Math.random() * 2.5) + 0.5;
      let animation = 'slide-up 25s infinite ease-in-out both';
      if(type === 'twinkle' && (i < num / 3)) {
        animation = `fade-in 10s infinite ${i * 500}ms ease-in-out both, slide-up 25s infinite ease-in-out both`;
      }
      stars.push(
        <Star
          key={top + '-' + left + '-' + i}
          style={{
            top,
            left,
            width: width + 'px',
            height: width + 'px',
            animation
          }}
        />
      );
    }
  }

  return stars;
};

function IndexPage() {
  const { dataJson: { products, services } } = useStaticQuery(graphql`
    query products {
      dataJson {
        products {
          name
          icon
          subHeading
          src {
            childImageSharp {
                fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                }
            }
            id
          }
          description
        }
        services {
          name
          icon
          src {
            childImageSharp {
                fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                }
            }
            id
          }
          description
        }
      }
    }
  `);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if(typeof window !== undefined) {
      const handleResize = () => {
        let windowWidth = 0;
        windowWidth = window.innerWidth;
        setWidth(windowWidth);
      };
      const handleScroll = () => {
        const productsElem = document.getElementById('products');
        const moonElem1 = document.getElementById('moon-1');
        const moonElem2 = document.getElementById('moon-2');
        if(productsElem) {
          const elemScrollTop = productsElem.getBoundingClientRect().top;
          const calcTop = Math.abs(elemScrollTop - window.innerHeight);
          if(calcTop > (window.innerHeight / 3) && calcTop < (window.innerHeight * 1.5)) {
            moonElem1.style.boxShadow = '15px 23px 50px -16px rgba(255,255,255,0.2)';
            moonElem2.style.boxShadow = '-13px -18px 54px -16px rgba(241,82,111,0.4)';
          } else {
            moonElem1.style.boxShadow = 'none';
            moonElem2.style.boxShadow = 'none';
          }
        }
      };
      handleResize();
      handleScroll();
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
      };
    }
  });

  return(
    <Layout footerType='normal'>
      <SEOComponent />
      <Section
        style={{
          height: width > 1024 ? 'calc(100vh - 110px)' : 'calc(100vh - 80px)'
        }}
        id='home'
      >
        <BackgroundBlock>
          <Movable
            elemId='home'
            style={{
              right: 0,
              top: 0
            }}
          >
            <svg style={{width: width > 1024 ? 'auto' : '75vw', height: width > 1024 ? 'auto' : '75vw'}} className="svg-1" xmlns="http://www.w3.org/2000/svg" width="479" height="433" viewBox="0 0 479 433">
              <defs>
                <radialGradient id="a" cx="48.411%" cy="-.009%" r="111.725%" fx="48.411%" fy="-.009%" gradientTransform="scale(-1 -.8427) rotate(-56.228 0 .906)">
                  <stop offset="0%" stopOpacity="0"/>
                  <stop offset="35.68%" stopColor="#F1526F" stopOpacity=".5"/>
                  <stop offset="61.62%" stopColor="#F1526F" stopOpacity=".4"/>
                  <stop offset="100%" stopOpacity="0"/>
                </radialGradient>
              </defs>
              <path fill="url(#a)" fillOpacity=".2" fillRule="nonzero" d="M1028.773 628.656c119.48 0 216.338-96.914 216.338-216.464s-184.24-447.633-216.338-216.464c-32.1 231.168-216.338 96.914-216.338 216.464s96.857 216.464 216.338 216.464z" transform="rotate(-85 553.514 716.408)"/>
            </svg>
          </Movable>
          <Movable
            elemId='home'
            style={{
              right: '30px',
              top: '30px'
            }}
          >
            <svg style={{width: width > 1024 ? 'auto' : '75vw', height: width > 1024 ? 'auto' : '75vw'}} className="svg-2" xmlns="http://www.w3.org/2000/svg" width="381" height="491" viewBox="0 0 381 491">
              <defs>
                <radialGradient id="b" cx="47.935%" cy=".563%" r="110.819%" fx="47.935%" fy=".563%" gradientTransform="scale(-1 -.84319) rotate(-56.212 -.012 .897)">
                  <stop offset="0%" stopColor="#F1526F" stopOpacity=".6"/>
                  <stop offset="36.832%" stopColor="#F1526F" stopOpacity=".6"/>
                  <stop offset="66.454%" stopColor="#F1526F" stopOpacity=".6"/>
                  <stop offset="100%" stopOpacity="0"/>
                </radialGradient>
              </defs>
              <path fill="url(#b)" fillOpacity=".2" fillRule="nonzero" d="M1062.815 624.833c119.48 0 216.338-96.858 216.338-216.338 0-119.48-184.239-447.372-216.338-216.338-32.099 231.034-216.338 96.858-216.338 216.338 0 119.48 96.858 216.338 216.338 216.338z" transform="rotate(-37 425.028 1648.675)"/>
            </svg>
          </Movable>
          <Movable
            elemId='home'
            style={{
              right: '100px',
              bottom: '100px'
            }}
          >
            <svg style={{width: width > 1024 ? 'auto' : '75vw', height: width > 1024 ? 'auto' : '75vw'}} xmlns="http://www.w3.org/2000/svg" width="276" height="248" viewBox="0 0 276 248">
              <defs>
                <linearGradient id="c" x1="88.451%" x2="48.181%" y1="97.066%" y2="39.462%">
                  <stop offset="0%" stopColor="#FFF" stopOpacity=".2"/>
                  <stop offset="100%" stopOpacity="0"/>
                </linearGradient>
              </defs>
              <ellipse cx="1025.477" cy="590.69" fill="url(#c)" fillRule="nonzero" rx="144.225" ry="143.866" transform="translate(-894 -487)"/>
            </svg>
          </Movable>
        </BackgroundBlock>
        <SectionContainer>
          <div id="section-1">
            <h1 style={{display: 'none'}}>Managing your Media on the Cloud</h1>
            <Fade bottom>
              <TypeText />
              <div className="changing-text-block">
                <p className="changing-top-text" />
                <p className="text-cursor" />
              </div>
              <p className="section-text">Storytelling</p>
              <div className="rotating-text-block">
                <p className="section-text">With</p>
                <div className="rotating-text">AI<br /> ML<br />Automation<br />Cloud<br />Data
                  Driven<br />Analytics<br />AI</div>
              </div>
              <p className="section-text">Technology</p>
            </Fade>
          </div>
        </SectionContainer>
      </Section>
      <Section
        style={{
          background: '#121217'
        }}
        id='about'
      >
        <BackgroundBlock>
          <Movable
            elemId='about'
          >
            <svg style={{width: width > 1024 ? 'auto' : '75vw', height: width > 1024 ? 'auto' : '75vw'}} xmlns="http://www.w3.org/2000/svg" width="423" height="410" viewBox="0 0 423 410">
              <defs>
                <linearGradient id="d" x1="74.95%" x2="1.425%" y1="53.242%" y2="3.15%">
                  <stop offset="0%" stopOpacity="0"/>
                  <stop offset="100%" stopColor="#F1526F" stopOpacity=".5"/>
                </linearGradient>
              </defs>
              <path fill="url(#d)" fillRule="nonzero" d="M289.233 1401c185.316 72 210-94.02 210-210s-94.02-210-210-210-72.077 159.293-191.655 218.75C-22 1259.207 103.916 1329 289.233 1401z" transform="translate(-50 -981)"/>
            </svg>
          </Movable>
          {createStars(50)}
        </BackgroundBlock>
        <SectionContainer>
          <div id="section-2">
            <Fade bottom>
              <p className="section-heading">We Create Tech</p>
              <p className="section-sub-heading">Tech that Summons Change</p>
              <p className="section-text">We're here to re-imagine a media production workspace without the usual theatre of scores of humans, cluttered spaces, unorganized communication and so much more.</p>
            </Fade>
          </div>
        </SectionContainer>
      </Section>
      <Section
        style={{
          background: '#000000'
        }}
        id='products'
      >
        <BackgroundBlock>
          <Moon
            id='moon-1'
            style={{
              width: width > 1024 ? '400px' : '200px',
              height: width > 1024 ? '400px' : '200px',
              left: width > 1024 ? '50px' : '20px',
              transform: 'rotate(-90deg)',
              bottom: width > 1024 ? '50px' : '20px',
              background: 'linear-gradient(-43deg,rgb(2, 2, 2) 50%,rgb(68,68,68))',
              transition: 'all 2000ms ease-in-out'
            }}
          />
          <Moon
            id='moon-2'
            style={{
              width: width > 1024 ? '400px' : '200px',
              height: width > 1024 ? '400px' : '200px',
              right: width > 1024 ? '50px' : '20px',
              top: width > 1024 ? '50px' : '20px',
              background: 'linear-gradient(319deg, rgb(241, 82, 111, 0.3) 10%, rgba(0, 0, 0, 1) 70%)',
              transition: 'all 2000ms ease-in-out'
            }}
          />
        </BackgroundBlock>
        <SectionContainer>
          <div id="section-3">
            <Fade bottom>
              <Icon name='contido' />
              <p className="section-sub-heading">Do more with your Content</p>
              <p className="section-text">A content supply chain management marvel aimed at decluttering and recalibrating the canvas of media production.</p>
              <div className='product-list'>
                {products.map((product) => {
                  return(
                    <div key={product.name} className="product-block">
                      <Icon
                        name={product.icon}
                        color='#999999'
                      />
                      <p className="product-name">{product.name}</p>
                    </div>
                  );
                })}
              </div>
              <div className="app-download-block">
                <div className="download-text-block">
                  <p className='download-text'>Download the Contido App</p>
                  <div className='svg-image'>
                    <a
                      href="https://apps.apple.com/in/app/contido/id1597407918"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Instagram"
                    >
                      <svg width="120" height="40" viewBox="0 0 120 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1002_4)">
                          <path d="M110.135 7.92967e-06H9.53468C9.16798 7.92967e-06 8.80568 7.92973e-06 8.43995 0.00200793C8.1338 0.00400793 7.83009 0.00981793 7.521 0.0147079C6.84951 0.0226097 6.1796 0.0816842 5.5171 0.191418C4.85552 0.303541 4.21467 0.514924 3.61622 0.818418C3.0185 1.12448 2.47234 1.52218 1.99757 1.99708C1.5203 2.47064 1.12246 3.01802 0.81935 3.61817C0.5154 4.21712 0.30464 4.85895 0.19435 5.52149C0.0830109 6.18319 0.0230984 6.85253 0.01515 7.52349C0.00587 7.83009 0.00489 8.13771 0 8.44435V31.5586C0.00489 31.8691 0.00587 32.1699 0.01515 32.4805C0.0231008 33.1514 0.0830134 33.8207 0.19435 34.4824C0.304336 35.1453 0.515108 35.7875 0.81935 36.3867C1.12233 36.9849 1.52022 37.5301 1.99757 38.001C2.47054 38.478 3.01705 38.876 3.61622 39.1797C4.21467 39.484 4.85545 39.6967 5.5171 39.8105C6.17972 39.9194 6.84956 39.9785 7.521 39.9873C7.83009 39.9941 8.1338 39.998 8.43995 39.998C8.80567 40 9.168 40 9.53468 40H110.135C110.494 40 110.859 40 111.219 39.998C111.523 39.998 111.836 39.9941 112.141 39.9873C112.811 39.9789 113.479 39.9198 114.141 39.8105C114.804 39.6959 115.448 39.4833 116.049 39.1797C116.647 38.8758 117.193 38.4779 117.666 38.001C118.142 37.5282 118.541 36.9835 118.848 36.3867C119.15 35.7871 119.358 35.1449 119.467 34.4824C119.578 33.8206 119.64 33.1515 119.652 32.4805C119.656 32.1699 119.656 31.8691 119.656 31.5586C119.664 31.1953 119.664 30.834 119.664 30.4648V9.53614C119.664 9.16993 119.664 8.80665 119.656 8.44435C119.656 8.13771 119.656 7.83009 119.652 7.52345C119.64 6.85243 119.578 6.18324 119.467 5.52145C119.358 4.85929 119.149 4.21751 118.848 3.61813C118.23 2.4152 117.252 1.43603 116.049 0.818328C115.448 0.515575 114.804 0.304248 114.141 0.191328C113.48 0.0811107 112.811 0.0220157 112.141 0.0145679C111.836 0.00968793 111.523 0.00382793 111.219 0.00187793C110.859 -0.00012207 110.494 -0.00012207 110.135 -0.00012207V7.92967e-06Z" fill="#A6A6A6" />
                          <path d="M8.44486 39.125C8.14018 39.125 7.84287 39.1211 7.54058 39.1143C6.91436 39.1061 6.2896 39.0516 5.67144 38.9512C5.09503 38.8519 4.53664 38.6673 4.0147 38.4033C3.49754 38.1415 3.02585 37.7983 2.6177 37.3867C2.20364 36.98 1.85891 36.5082 1.59719 35.9902C1.33258 35.4688 1.14945 34.9099 1.05419 34.333C0.951311 33.7131 0.895651 33.0863 0.887687 32.458C0.881347 32.2471 0.873047 31.5449 0.873047 31.5449V8.44434C0.873047 8.44434 0.881887 7.75293 0.887737 7.5498C0.895363 6.92248 0.950699 6.29665 1.05327 5.67773C1.14871 5.09925 1.33197 4.53875 1.59673 4.01563C1.85749 3.49794 2.2003 3.02586 2.61187 2.61768C3.02297 2.20562 3.49617 1.8606 4.01421 1.59521C4.53495 1.33209 5.09228 1.14873 5.66753 1.05127C6.28772 0.949836 6.91465 0.894996 7.54304 0.88721L8.44536 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.606 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.71 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44486 39.125Z" fill="black" />
                          <path d="M24.769 20.3006C24.7797 19.4659 25.0014 18.6475 25.4135 17.9216C25.8255 17.1956 26.4145 16.5857 27.1256 16.1485C26.6738 15.5034 26.0779 14.9724 25.385 14.5979C24.6922 14.2233 23.9216 14.0155 23.1344 13.9908C21.4552 13.8146 19.8272 14.9957 18.9715 14.9957C18.0992 14.9957 16.7817 14.0083 15.363 14.0375C14.4453 14.0672 13.551 14.334 12.7671 14.8121C11.9832 15.2901 11.3365 15.9631 10.8901 16.7654C8.95607 20.1138 10.3987 25.0349 12.2513 27.7415C13.1782 29.0668 14.2615 30.5473 15.6789 30.4948C17.066 30.4372 17.584 29.6103 19.2583 29.6103C20.9171 29.6103 21.4031 30.4948 22.8493 30.4614C24.3377 30.4372 25.2754 29.1302 26.1698 27.7923C26.8358 26.8479 27.3483 25.8042 27.6882 24.6997C26.8235 24.334 26.0856 23.7218 25.5665 22.9395C25.0474 22.1572 24.77 21.2394 24.769 20.3006V20.3006Z" fill="white" />
                          <path d="M22.0376 12.2108C22.8491 11.2366 23.2489 9.98445 23.1521 8.72021C21.9123 8.85044 20.767 9.44299 19.9445 10.3798C19.5424 10.8375 19.2344 11.3699 19.0381 11.9467C18.8419 12.5235 18.7612 13.1333 18.8008 13.7412C19.4209 13.7476 20.0344 13.6132 20.5951 13.3481C21.1558 13.083 21.649 12.6942 22.0376 12.2108Z" fill="white" />
                          <path d="M42.3022 27.1396H37.5688L36.4321 30.496H34.4272L38.9106 18.078H40.9936L45.477 30.496H43.438L42.3022 27.1396ZM38.0591 25.5907H41.8111L39.9615 20.1435H39.9097L38.0591 25.5907Z" fill="white" />
                          <path d="M55.1597 25.9697C55.1597 28.7832 53.6538 30.5908 51.3814 30.5908C50.8057 30.6209 50.2332 30.4883 49.7294 30.2082C49.2256 29.928 48.8109 29.5117 48.5327 29.0068H48.4897V33.4912H46.6313V21.4423H48.4302V22.9482H48.4644C48.7553 22.4457 49.1771 22.0316 49.6847 21.7497C50.1923 21.4679 50.7669 21.3289 51.3472 21.3476C53.645 21.3476 55.1597 23.164 55.1597 25.9697ZM53.2495 25.9697C53.2495 24.1367 52.3023 22.9316 50.857 22.9316C49.437 22.9316 48.482 24.1621 48.482 25.9697C48.482 27.7939 49.437 29.0156 50.857 29.0156C52.3023 29.0156 53.2495 27.8193 53.2495 25.9697Z" fill="white" />
                          <path d="M65.1245 25.9697C65.1245 28.7832 63.6187 30.5908 61.3462 30.5908C60.7706 30.6209 60.1981 30.4883 59.6943 30.2081C59.1905 29.928 58.7758 29.5117 58.4976 29.0068H58.4546V33.4912H56.5962V21.4423H58.395V22.9482H58.4292C58.7201 22.4457 59.1419 22.0315 59.6495 21.7497C60.1571 21.4679 60.7317 21.3289 61.312 21.3476C63.6099 21.3476 65.1245 23.164 65.1245 25.9697ZM63.2144 25.9697C63.2144 24.1367 62.2671 22.9316 60.8218 22.9316C59.4019 22.9316 58.4468 24.1621 58.4468 25.9697C58.4468 27.7939 59.4019 29.0156 60.8218 29.0156C62.2671 29.0156 63.2144 27.8193 63.2144 25.9697H63.2144Z" fill="white" />
                          <path d="M71.7105 27.0361C71.8482 28.2675 73.0445 29.0761 74.6792 29.0761C76.2456 29.0761 77.3726 28.2675 77.3726 27.1571C77.3726 26.1932 76.6929 25.6161 75.0835 25.2206L73.4742 24.8329C71.1939 24.2821 70.1353 23.2157 70.1353 21.4852C70.1353 19.3427 72.0025 17.871 74.6538 17.871C77.2778 17.871 79.0767 19.3427 79.1372 21.4852H77.2612C77.1489 20.246 76.1245 19.4979 74.6274 19.4979C73.1304 19.4979 72.106 20.2548 72.106 21.3563C72.106 22.2343 72.7603 22.7509 74.3608 23.1463L75.729 23.4823C78.2769 24.0848 79.3355 25.1083 79.3355 26.9246C79.3355 29.2479 77.4849 30.703 74.5415 30.703C71.7876 30.703 69.9282 29.2821 69.8081 27.036L71.7105 27.0361Z" fill="white" />
                          <path d="M83.3462 19.2997V21.4423H85.0679V22.9139H83.3462V27.9052C83.3462 28.6805 83.6909 29.0419 84.4477 29.0419C84.6521 29.0383 84.8562 29.024 85.0591 28.9989V30.4618C84.7188 30.5253 84.3729 30.5541 84.0268 30.5477C82.1938 30.5477 81.479 29.8592 81.479 28.1034V22.9139H80.1626V21.4423H81.479V19.2997H83.3462Z" fill="white" />
                          <path d="M86.0649 25.9697C86.0649 23.1211 87.7427 21.3311 90.3589 21.3311C92.9839 21.3311 94.6538 23.1211 94.6538 25.9697C94.6538 28.8262 92.9927 30.6084 90.3589 30.6084C87.726 30.6084 86.0649 28.8262 86.0649 25.9697ZM92.7603 25.9697C92.7603 24.0156 91.8647 22.8623 90.3589 22.8623C88.853 22.8623 87.9585 24.0244 87.9585 25.9697C87.9585 27.9316 88.853 29.0762 90.3589 29.0762C91.8647 29.0762 92.7602 27.9316 92.7602 25.9697H92.7603Z" fill="white" />
                          <path d="M96.186 21.4423H97.9585V22.9833H98.0015C98.1214 22.502 98.4034 22.0766 98.8 21.7788C99.1965 21.4809 99.6836 21.3286 100.179 21.3475C100.393 21.3468 100.607 21.37 100.816 21.4169V23.1551C100.546 23.0725 100.264 23.0346 99.9809 23.0428C99.711 23.0319 99.4418 23.0795 99.192 23.1824C98.9421 23.2853 98.7175 23.441 98.5335 23.6389C98.3496 23.8368 98.2106 24.0721 98.1262 24.3288C98.0417 24.5855 98.0139 24.8574 98.0444 25.1258V30.496H96.186L96.186 21.4423Z" fill="white" />
                          <path d="M109.384 27.8368C109.134 29.4803 107.534 30.6083 105.486 30.6083C102.852 30.6083 101.217 28.8436 101.217 26.0126C101.217 23.1727 102.861 21.3309 105.408 21.3309C107.913 21.3309 109.488 23.0516 109.488 25.7968V26.4335H103.093V26.5458C103.064 26.879 103.105 27.2146 103.216 27.5305C103.326 27.8463 103.502 28.1351 103.732 28.3777C103.963 28.6202 104.242 28.811 104.552 28.9373C104.861 29.0636 105.195 29.1225 105.529 29.1102C105.968 29.1514 106.409 29.0497 106.785 28.8202C107.162 28.5908 107.455 28.2459 107.62 27.8368L109.384 27.8368ZM103.102 25.1346H107.628C107.645 24.835 107.6 24.5352 107.495 24.254C107.39 23.9728 107.229 23.7163 107.02 23.5005C106.812 23.2847 106.561 23.1144 106.283 23.0002C106.006 22.886 105.708 22.8303 105.408 22.8368C105.105 22.835 104.805 22.8931 104.525 23.0079C104.245 23.1226 103.99 23.2917 103.776 23.5053C103.562 23.719 103.392 23.9729 103.276 24.2526C103.16 24.5322 103.101 24.832 103.102 25.1347V25.1346Z" fill="white" />
                          <path d="M37.8262 8.73101C38.2158 8.70305 38.6067 8.76191 38.9709 8.90335C39.335 9.04478 39.6632 9.26526 39.9317 9.54889C40.2003 9.83251 40.4026 10.1722 40.524 10.5435C40.6454 10.9148 40.6829 11.3083 40.6338 11.6959C40.6338 13.6021 39.6035 14.6979 37.8262 14.6979H35.6709V8.73101H37.8262ZM36.5976 13.854H37.7226C38.0011 13.8707 38.2797 13.825 38.5382 13.7204C38.7967 13.6158 39.0287 13.4548 39.2172 13.2493C39.4057 13.0437 39.546 12.7987 39.6278 12.5321C39.7097 12.2655 39.7311 11.9839 39.6904 11.708C39.7281 11.4332 39.7046 11.1534 39.6215 10.8887C39.5383 10.6241 39.3977 10.3811 39.2096 10.1771C39.0216 9.97322 38.7907 9.81341 38.5337 9.70917C38.2766 9.60494 37.9996 9.55885 37.7226 9.57422H36.5976V13.854Z" fill="white" />
                          <path d="M41.6805 12.4444C41.6521 12.1485 41.686 11.8499 41.7798 11.5679C41.8737 11.2858 42.0254 11.0264 42.2254 10.8065C42.4253 10.5865 42.669 10.4107 42.9409 10.2904C43.2127 10.1702 43.5067 10.108 43.804 10.108C44.1013 10.108 44.3952 10.1702 44.6671 10.2904C44.9389 10.4107 45.1827 10.5865 45.3826 10.8065C45.5825 11.0264 45.7343 11.2858 45.8281 11.5679C45.922 11.8499 45.9558 12.1485 45.9275 12.4444C45.9564 12.7406 45.9229 13.0396 45.8293 13.3221C45.7357 13.6047 45.5841 13.8645 45.3841 14.0849C45.1841 14.3053 44.9402 14.4814 44.6681 14.602C44.3959 14.7225 44.1016 14.7848 43.804 14.7848C43.5064 14.7848 43.212 14.7225 42.9399 14.602C42.6678 14.4814 42.4239 14.3053 42.2239 14.0849C42.0239 13.8645 41.8722 13.6047 41.7786 13.3221C41.6851 13.0396 41.6516 12.7406 41.6805 12.4444V12.4444ZM45.0135 12.4444C45.0135 11.4683 44.575 10.8975 43.8055 10.8975C43.033 10.8975 42.5985 11.4683 42.5985 12.4444C42.5985 13.4283 43.033 13.9947 43.8055 13.9947C44.575 13.9947 45.0135 13.4244 45.0135 12.4444H45.0135Z" fill="white" />
                          <path d="M51.5732 14.6978H50.6514L49.7207 11.3813H49.6504L48.7236 14.6978H47.8105L46.5693 10.1948H47.4707L48.2773 13.6308H48.3438L49.2695 10.1948H50.1221L51.0478 13.6308H51.1182L51.9209 10.1948H52.8096L51.5732 14.6978Z" fill="white" />
                          <path d="M53.8535 10.1949H54.709V10.9102H54.7754C54.8881 10.6533 55.0781 10.4379 55.3189 10.2941C55.5598 10.1503 55.8395 10.0852 56.1191 10.1079C56.3382 10.0915 56.5582 10.1245 56.7628 10.2046C56.9674 10.2847 57.1514 10.4098 57.3011 10.5707C57.4508 10.7315 57.5623 10.924 57.6275 11.1338C57.6927 11.3436 57.7099 11.5654 57.6777 11.7827V14.6977H56.7891V12.0059C56.7891 11.2823 56.4746 10.9224 55.8174 10.9224C55.6686 10.9155 55.5201 10.9408 55.3821 10.9966C55.244 11.0525 55.1197 11.1375 55.0175 11.2459C54.9154 11.3542 54.8379 11.4834 54.7904 11.6246C54.7429 11.7657 54.7264 11.9154 54.7422 12.0635V14.6978H53.8535L53.8535 10.1949Z" fill="white" />
                          <path d="M59.0938 8.43701H59.9824V14.6978H59.0938V8.43701Z" fill="white" />
                          <path d="M61.2175 12.4444C61.1893 12.1484 61.2231 11.8499 61.317 11.5678C61.4109 11.2857 61.5626 11.0263 61.7626 10.8064C61.9626 10.5864 62.2063 10.4106 62.4782 10.2903C62.75 10.17 63.044 10.1079 63.3413 10.1079C63.6386 10.1079 63.9326 10.17 64.2045 10.2903C64.4764 10.4106 64.7201 10.5864 64.92 10.8064C65.12 11.0263 65.2718 11.2857 65.3656 11.5678C65.4595 11.8499 65.4934 12.1484 65.4651 12.4444C65.4939 12.7406 65.4604 13.0396 65.3668 13.3221C65.2732 13.6046 65.1215 13.8645 64.9215 14.0849C64.7215 14.3053 64.4776 14.4814 64.2054 14.6019C63.9333 14.7225 63.639 14.7847 63.3413 14.7847C63.0437 14.7847 62.7493 14.7225 62.4772 14.6019C62.2051 14.4814 61.9612 14.3053 61.7612 14.0849C61.5611 13.8645 61.4095 13.6046 61.3158 13.3221C61.2222 13.0396 61.1887 12.7406 61.2175 12.4444V12.4444ZM64.5505 12.4444C64.5505 11.4683 64.1121 10.8975 63.3425 10.8975C62.5701 10.8975 62.1355 11.4683 62.1355 12.4444C62.1355 13.4283 62.5701 13.9947 63.3425 13.9947C64.1121 13.9947 64.5505 13.4244 64.5505 12.4444H64.5505Z" fill="white" />
                          <path d="M66.4009 13.4243C66.4009 12.6138 67.0044 12.1465 68.0757 12.0801L69.2954 12.0098V11.6211C69.2954 11.1455 68.981 10.877 68.3735 10.877C67.8774 10.877 67.5337 11.0591 67.4351 11.3774H66.5747C66.6655 10.604 67.3931 10.1079 68.4146 10.1079C69.5435 10.1079 70.1802 10.6699 70.1802 11.6211V14.6977H69.3247V14.0649H69.2544C69.1117 14.2919 68.9113 14.477 68.6737 14.6012C68.436 14.7254 68.1697 14.7843 67.9019 14.7719C67.7128 14.7916 67.5218 14.7714 67.341 14.7127C67.1602 14.654 66.9938 14.5581 66.8524 14.4311C66.711 14.3042 66.5977 14.149 66.52 13.9756C66.4422 13.8021 66.4016 13.6144 66.4009 13.4243V13.4243ZM69.2954 13.0395V12.6631L68.1958 12.7334C67.5757 12.7749 67.2944 12.9858 67.2944 13.3828C67.2944 13.7881 67.646 14.0239 68.1294 14.0239C68.2711 14.0382 68.4142 14.0239 68.5502 13.9819C68.6862 13.9398 68.8123 13.8707 68.9211 13.7789C69.0299 13.6871 69.1191 13.5743 69.1834 13.4473C69.2477 13.3202 69.2858 13.1816 69.2954 13.0395V13.0395Z" fill="white" />
                          <path d="M71.3481 12.4444C71.3481 11.0215 72.0796 10.1201 73.2173 10.1201C73.4987 10.1072 73.778 10.1746 74.0225 10.3145C74.267 10.4544 74.4667 10.661 74.5981 10.9101H74.6646V8.43701H75.5532V14.6978H74.7017V13.9863H74.6314C74.4897 14.2338 74.2831 14.4378 74.0339 14.5763C73.7846 14.7148 73.5022 14.7825 73.2173 14.772C72.0718 14.772 71.3481 13.8706 71.3481 12.4444ZM72.2661 12.4444C72.2661 13.3994 72.7163 13.9741 73.4693 13.9741C74.2183 13.9741 74.6812 13.3911 74.6812 12.4483C74.6812 11.5098 74.2134 10.9185 73.4693 10.9185C72.7212 10.9185 72.2661 11.4971 72.2661 12.4444H72.2661Z" fill="white" />
                          <path d="M79.2298 12.4444C79.2015 12.1485 79.2353 11.8499 79.3292 11.5679C79.423 11.2858 79.5748 11.0264 79.7747 10.8065C79.9746 10.5865 80.2183 10.4107 80.4902 10.2904C80.762 10.1702 81.056 10.108 81.3533 10.108C81.6506 10.108 81.9446 10.1702 82.2164 10.2904C82.4883 10.4107 82.732 10.5865 82.9319 10.8065C83.1319 11.0264 83.2836 11.2858 83.3775 11.5679C83.4713 11.8499 83.5052 12.1485 83.4768 12.4444C83.5057 12.7406 83.4722 13.0396 83.3786 13.3221C83.2851 13.6047 83.1334 13.8645 82.9334 14.0849C82.7334 14.3053 82.4895 14.4814 82.2174 14.602C81.9453 14.7225 81.6509 14.7848 81.3533 14.7848C81.0557 14.7848 80.7614 14.7225 80.4892 14.602C80.2171 14.4814 79.9732 14.3053 79.7732 14.0849C79.5732 13.8645 79.4216 13.6047 79.328 13.3221C79.2344 13.0396 79.2009 12.7406 79.2298 12.4444V12.4444ZM82.5628 12.4444C82.5628 11.4683 82.1243 10.8975 81.3548 10.8975C80.5823 10.8975 80.1478 11.4683 80.1478 12.4444C80.1478 13.4283 80.5823 13.9947 81.3548 13.9947C82.1243 13.9947 82.5628 13.4244 82.5628 12.4444Z" fill="white" />
                          <path d="M84.6694 10.1949H85.5249V10.9102H85.5913C85.704 10.6533 85.894 10.4379 86.1348 10.2941C86.3757 10.1503 86.6555 10.0852 86.9351 10.1079C87.1542 10.0915 87.3742 10.1245 87.5788 10.2046C87.7834 10.2847 87.9673 10.4098 88.117 10.5707C88.2667 10.7315 88.3783 10.924 88.4435 11.1338C88.5087 11.3436 88.5258 11.5654 88.4937 11.7827V14.6977H87.605V12.0059C87.605 11.2823 87.2905 10.9224 86.6333 10.9224C86.4846 10.9155 86.336 10.9408 86.198 10.9966C86.0599 11.0525 85.9356 11.1375 85.8335 11.2459C85.7313 11.3542 85.6539 11.4834 85.6063 11.6246C85.5588 11.7657 85.5424 11.9154 85.5581 12.0635V14.6978H84.6694V10.1949Z" fill="white" />
                          <path d="M93.5151 9.07373V10.2153H94.4907V10.9639H93.5151V13.2793C93.5151 13.751 93.7095 13.9575 94.1519 13.9575C94.2651 13.9572 94.3783 13.9503 94.4907 13.937V14.6772C94.3311 14.7058 94.1694 14.721 94.0073 14.7226C93.019 14.7226 92.6255 14.375 92.6255 13.5068V10.9638H91.9106V10.2153H92.6255V9.07373H93.5151Z" fill="white" />
                          <path d="M95.7046 8.43701H96.5855V10.9185H96.6558C96.7739 10.6591 96.9691 10.4425 97.2148 10.2982C97.4605 10.1539 97.7447 10.0888 98.0288 10.1118C98.2467 10.1 98.4646 10.1363 98.6669 10.2184C98.8692 10.3004 99.0509 10.4261 99.199 10.5864C99.3471 10.7468 99.458 10.9378 99.5238 11.146C99.5896 11.3541 99.6086 11.5742 99.5795 11.7905V14.6978H98.69V12.0098C98.69 11.2905 98.355 10.9263 97.7271 10.9263C97.5744 10.9137 97.4207 10.9347 97.2769 10.9878C97.1332 11.0408 97.0027 11.1247 96.8947 11.2334C96.7867 11.3421 96.7038 11.4732 96.6517 11.6173C96.5997 11.7614 96.5797 11.9152 96.5933 12.0679V14.6977H95.7046L95.7046 8.43701Z" fill="white" />
                          <path d="M104.761 13.4819C104.641 13.8934 104.379 14.2494 104.022 14.4876C103.666 14.7257 103.237 14.8309 102.81 14.7846C102.514 14.7924 102.219 14.7356 101.946 14.6181C101.674 14.5006 101.43 14.3251 101.232 14.104C101.034 13.8829 100.887 13.6213 100.8 13.3375C100.713 13.0537 100.69 12.7544 100.73 12.4604C100.691 12.1655 100.715 11.8656 100.801 11.5809C100.888 11.2962 101.035 11.0335 101.231 10.8104C101.428 10.5873 101.671 10.4092 101.943 10.2879C102.214 10.1667 102.509 10.1053 102.806 10.1079C104.059 10.1079 104.815 10.9639 104.815 12.3779V12.688H101.635V12.7378C101.621 12.903 101.642 13.0693 101.696 13.2261C101.751 13.3828 101.837 13.5265 101.95 13.648C102.063 13.7694 102.2 13.866 102.352 13.9314C102.504 13.9968 102.669 14.0296 102.835 14.0278C103.047 14.0533 103.263 14.015 103.453 13.9178C103.644 13.8206 103.802 13.6688 103.906 13.4819L104.761 13.4819ZM101.635 12.0307H103.91C103.921 11.8796 103.9 11.7278 103.849 11.5851C103.799 11.4423 103.718 11.3119 103.614 11.202C103.51 11.0922 103.383 11.0053 103.243 10.9472C103.103 10.889 102.953 10.8607 102.801 10.8642C102.648 10.8623 102.495 10.8911 102.353 10.9491C102.21 11.007 102.081 11.0929 101.972 11.2016C101.864 11.3103 101.778 11.4397 101.72 11.5821C101.662 11.7245 101.633 11.877 101.635 12.0307H101.635Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1002_4">
                            <rect width="119.664" height="40" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="iso-block">
                <p className="iso-text">Desynova complies with <span className="iso-bold-text">ISO/IEC 27001:2013 certification</span> to protect your content and data.</p>
              </div>
            </Fade>
          </div>
        </SectionContainer>
      </Section>
      <Section
        style={{ padding: 0 }}
      >
        <SectionContainer>
          { width ?
            <Carousel
              type= {width > 768 ? 'web' : 'mobile'}
              slidePerPage='single'
              data={products}
              id='products-carousel'
            /> : null
          }
        </SectionContainer>
      </Section>
      <Section
        id='services'
      >
        <BackgroundBlock>
          {createStars(30, 'twinkle')}
          <Movable
            elemId='services'
          >
            <svg style={{width: width > 1024 ? 'auto' : '75vw', height: width > 1024 ? 'auto' : '75vw'}} xmlns="http://www.w3.org/2000/svg" width="500" height="514" viewBox="0 0 500 514">
              <defs>
                <linearGradient id="e" x1="0%" y1="0%" y2="100%">
                  <stop offset="0%" stopOpacity="0"/>
                  <stop offset="100%" stopColor="#F1526F" stopOpacity=".4"/>
                </linearGradient>
              </defs>
              <path fill="url(#e)" fillRule="evenodd" d="M167.32 3707.565c51.9 160.791 168.249 224.986 275.522 142.336 116.569-82.649 155.476-250.998 69.993-377.065-94.733-127.219-245.197-191.415-324.863-142.336-76.386 49.08-80.833 217.428-20.653 377.065h.001z" transform="rotate(156 640.801 1923.703)"/>
            </svg>
          </Movable>
        </BackgroundBlock>
        <SectionContainer>
          <div id="section-4">
            <Fade bottom>
              <p className="section-heading">Do more with your content</p>
              <p className="section-sub-heading">Our Services are our Expertise</p>
              <p className="section-text">Whether you run the big game or you wish to become a part of one, our unparalleled services will always stack the odds in your favour.</p>
            </Fade>
          </div>
        </SectionContainer>
      </Section>
      <Section
        style={{ padding: 0 }}
      >
        <SectionContainer>
          { width ?
            <Carousel
              type= {width > 768 ? 'web' : 'mobile'}
              slidePerPage='single'
              data={services}
              id='services-carousel'
            /> : null
          }
        </SectionContainer>
      </Section>
      <Section
        style={{
          height: width > 1024 ? 'calc(100vh - 270px)' : 'calc(100vh - 160px)',
          background: '100% 0% / cover url("./images/section-5.jpg") no-repeat'
        }}
      >
        <SectionContainer>
          <div id="section-5">
            <Fade bottom>
              <p className="section-heading">Bring yourself to the front row</p>
              <p
                onClick={() => openForm()}
                role="presentation"
                className='contact-btn'>Get In Touch</p>
            </Fade>
          </div>
        </SectionContainer>
      </Section>
    </Layout>
  );
}

export default IndexPage;
