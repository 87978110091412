import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Img from "gatsby-image"

import Icon from "../icon";

const CarouselContainer = styled.div`
    background: #f1526f;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media(max-width: 768px) {
        display:none;
    }
    .section-left, .section-right {
        position: relative;
        width: 50%;
        height: 100%;
        min-width: 50%;
        display: inline-block;
        overflow: hidden;
        .carousel-block {
            position: absolute;
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            .icon-block {
                transition: opacity 450ms cubic-bezier(.02, .01, .47, 1);
            }
            .details-block .heading span {
                opacity: 0;
            }
            .details-block .sub-heading, .details-block .description {
                transition: transform 550ms cubic-bezier(.02, .01, .47, 1), opacity 150ms cubic-bezier(.02, .01, .47, 1);
                opacity: 0;
            }
            .carousal-image {
                transition: all 750ms cubic-bezier(.02, .01, .47, 1);
                opacity: 0;
            }
            &.active {
                .icon-block {
                    opacity: 1 !important;
                    z-index: 10;
                }
                .details-block .sub-heading, .details-block .description {
                    transform: translate(0,0);
                    opacity: 1;
                    z-index: 10;
                }
                .carousal-image {
                    transform: translate(-50%,-50%) scale(1);
                    opacity: 1;
                    z-index: 10;
                }
            }
            &.prev {
                .details-block .heading span {
                    transform: translate(0,-100%);
                }
                .details-block .sub-heading, .details-block .description, .carousal-image {
                    transform: translate(0,-60%);
                }
                .carousal-image {
                    transform: translate(-50%,-150%) scale(1.5);
                }
            }
            &.next {
                .details-block .heading span {
                    transform: translate(0,100%);
                }
                .details-block .sub-heading, .details-block .description, .carousal-image {
                    transform: translate(0,60%);
                }
                .carousal-image {
                    transform: translate(-50%,50%) scale(1.5);
                }
            }
            .carousal-image {
                position: absolute;
                width: auto;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .section-left {
        padding: calc(20px + (200 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        @media (max-width: 1024px) {
            padding: calc(20px + (200 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        }
        @media (max-width: 768px) {
            padding: calc(20px + (200 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
        }
        .carousel-data-block {
            width: 100%;
            height: 100%;
            position: relative;
            .carousel-block {
                .icon-block {
                    width: 80px;
                    height: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    background-color: rgba(255, 255, 255, 0.1);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    opacity: 0;
                }
                .details-block {
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    width: 100%;
                    overflow: hidden;
                    margin-bottom: 40px;
                    min-height: 220px;
                    .heading {
                        font-size: calc(40px + (95 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        font-family: HarabaraMais-Bold;
                        color: #fff;
                        margin-bottom: 10px;
                        line-height: 1.1;
                        overflow: hidden;
                        font-weight: normal;
                        @media (max-width: 1024px) {
                            font-size: calc(40px + (95 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        }
                        @media (max-width: 768px) {
                            font-size: calc(40px + (95 - 40) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        }
                        span {
                            display: inline-block;
                        }
                    }
                    .sub-heading {
                        color: #000;
                        font-family: SFUIText-Medium;
                        font-size: calc(18px + (40 - 18) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        font-weight: bolder;
                        margin-bottom: 30px;
                        @media (max-width: 1024px) {
                            font-size: calc(18px + (40 - 18) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        }
                        @media (max-width: 768px) {
                            font-size: calc(18px + (40 - 18) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        }
                    }
                    .description {
                        color: #ffffff;
                        font-size: calc(14px + (20 - 14) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        font-family: SFUIText-Medium;
                        @media (max-width: 1024px) {
                            font-size: calc(14px + (20 - 14) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        }
                        @media (max-width: 768px) {
                            font-size: calc(14px + (20 - 14) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
                        }
                    }
                }
            }
        }
        .navigation-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .navigation {
                display: flex;
                justify-content: center;
                align-items: center;
                .navigation-button {
                    width: 35px;
                    height: 35px;
                    border: 1px solid #ffffff;
                    border-radius: 100%;
                    margin-right: 20px;
                    position: relative;
                    transition: all 250ms ease-in-out;
                    cursor: pointer;
                    svg path {
                        transition: all 250ms ease-in-out;
                    }
                    &:hover {
                        border: 1px solid #000000;
                        svg path {
                            fill: #000;
                        }
                    }
                    i {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        line-height: 0.5;
                    }
                }
            }
            .carousel-progress {
                display: flex;
                justify-content: center;
                align-items: center;
                .slide-num {
                    font-size: 14px;
                    font-family: SFUIText-Medium;
                    color: #fff;
                    margin: 0 10px;
                }
                .progress-bar-block {
                    width: 100px;
                    height: 2px;
                    position: relative;
                    background: rgba(255,255,255,0.5);
                }
                .progress-bar {
                    background: #ffffff;
                    height: 2px;
                    position: absolute;
                    color: #fff;
                    left: 0;
                    top: 0;
                    width: 0;
                }
            }
        }
    }
`;

export default class FullPageCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carouselData: [],
            currentSlide: 1,
            totalSlide: 0
        }
        this.carouselInterval = null;
    }

    componentDidMount() {
        const { dataArr, id } = this.props;
        dataArr.map((data, index) => {
            var carousel = Object.assign({}, data);
            var className = 'carousel-block ' + data.icon;
            if(index < 1) {
                className = className + ' active';
            } else if(index === 1) {
                className = className + ' next';
            } else if(index === dataArr.length - 1) {
                className = className + ' prev';
            }
            carousel.className = className;

            this.setState((state) => ({
                carouselData: [...state.carouselData, carousel],
                totalSlide: state.totalSlide + 1
            }))

            return data;
        })
        setTimeout(() => {
            let activeHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.active .details-block .heading span`))
            activeHeadingArr.forEach((elem, index) => {
                elem.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
                elem.style.transform = `transform: translate(0, 0);`;
                elem.style.opacity = `1`;
            })
            var progressBar = document.querySelectorAll(`#${id} .progress-bar`)[0];
            progressBar.style.animation = 'none';
            setTimeout(function() {
                progressBar.style.animation = `progress-fill 10s 1 ease-in-out both`;
            }, 10)
            this.playCarousel();
        }, 0);
    }

    componentWillUnmount() {
        clearInterval(this.carouselInterval);
        this.carouselInterval = null;
    }

    playCarousel(){
        if(!this.carouselInterval) {
            var self = this;
            this.carouselInterval = setInterval(function () {
                self.changeSlide('right', 'auto')
            }, 10000)
        }
    }

    changeSlide(position, type) {
        const { id } = this.props;
        if(type === 'manual') {
            clearInterval(this.carouselInterval);
            this.carouselInterval = null;
            this.playCarousel();
        }
        const leftSection = this._leftSectionRef;
        const rightSection = this._rightSectionRef;
        let leftChildsArr = Array.from(leftSection.children[0].children);
        let rightChildsArr = Array.from(rightSection.children);
        let prevIndex = leftChildsArr.findIndex((elem) => elem.classList.contains('prev'));
        let nextIndex = leftChildsArr.findIndex((elem) => elem.classList.contains('next'));
        let activeIndex = leftChildsArr.findIndex((elem) => elem.classList.contains('active'));
        let activeHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.active .details-block .heading .span-block`))
        let prevHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.prev .details-block .heading .span-block`))
        let nextHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.next .details-block .heading .span-block`))
        if(position === 'right') {
            activeHeadingArr.forEach((elem) => {
                var spanChildArr = Array.from(elem.children);
                spanChildArr.forEach((span, index) => {
                    span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
                    span.style.transform = `transform: translate(0px, -100%)`;
                    span.style.opacity = `0`;
                })
            })
            nextHeadingArr.forEach((elem) => {
                var spanChildArr = Array.from(elem.children);
                spanChildArr.forEach((span, index) => {
                    span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
                    span.style.transform = `transform: translate(0, 0);`;
                    span.style.opacity = `1`;
                })
            })
            setTimeout(() => {
                let upcomingNextIndex = nextIndex + 1 > leftChildsArr.length - 1 ? 0 : nextIndex + 1
                leftChildsArr[prevIndex].classList.remove('prev');
                leftChildsArr[activeIndex].classList.remove('active');
                leftChildsArr[activeIndex].classList.add('prev');
                leftChildsArr[nextIndex].classList.add('active');
                leftChildsArr[nextIndex].classList.remove('next');
                leftChildsArr[upcomingNextIndex].classList.add('next');
                rightChildsArr[prevIndex].classList.remove('prev');
                rightChildsArr[activeIndex].classList.remove('active');
                rightChildsArr[activeIndex].classList.add('prev');
                rightChildsArr[nextIndex].classList.add('active');
                rightChildsArr[nextIndex].classList.remove('next');
                rightChildsArr[upcomingNextIndex].classList.add('next');
                this.setState({
                    currentSlide: nextIndex + 1
                })
                nextHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.next .details-block .heading .span-block`))
                nextHeadingArr.forEach((elem) => {
                    var spanChildArr = Array.from(elem.children);
                    spanChildArr.forEach((span, index) => {
                        span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
                        span.style.transform = `transform: translate(0, 100%)`;
                        span.style.opacity = `0`;
                    })
                })
            }, 0);
        }
        if(position === 'left') {
            activeHeadingArr.forEach((elem) => {
                var spanChildArr = Array.from(elem.children);
                spanChildArr.forEach((span, index) => {
                    span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
                    span.style.transform = `transform: translate(0px, 100%)`;
                    span.style.opacity = `0`;
                })
            })
            prevHeadingArr.forEach((elem) => {
                var spanChildArr = Array.from(elem.children);
                spanChildArr.forEach((span, index) => {
                    span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
                    span.style.transform = `transform: translate(0, 0);`;
                    span.style.opacity = `1`;
                })
            })
            setTimeout(() => {
                let upcomingPrevIndex = prevIndex - 1 < 0 ? leftChildsArr.length - 1 : prevIndex - 1
                leftChildsArr[nextIndex].classList.remove('next');
                leftChildsArr[activeIndex].classList.remove('active');
                leftChildsArr[activeIndex].classList.add('next');
                leftChildsArr[prevIndex].classList.add('active');
                leftChildsArr[prevIndex].classList.remove('prev');
                leftChildsArr[upcomingPrevIndex].classList.add('prev');
                rightChildsArr[nextIndex].classList.remove('next');
                rightChildsArr[activeIndex].classList.remove('active');
                rightChildsArr[activeIndex].classList.add('next');
                rightChildsArr[prevIndex].classList.add('active');
                rightChildsArr[prevIndex].classList.remove('prev');
                rightChildsArr[upcomingPrevIndex].classList.add('prev');
                this.setState({
                    currentSlide: prevIndex + 1
                })
                prevHeadingArr = Array.from(document.querySelectorAll(`#${id} .carousel-block.prev .details-block .heading .span-block`))
                prevHeadingArr.forEach((elem) => {
                    var spanChildArr = Array.from(elem.children);
                    spanChildArr.forEach((span, index) => {
                        span.style.transition = `all 350ms ease-in-out ${index * 50}ms`;
                        span.style.transform = `transform: translate(0, -100%)`;
                        span.style.opacity = `0`;
                    })
                })
            }, 0);
        }

        var progressBar = document.querySelectorAll(`#${id} .progress-bar`)[0];
        progressBar.style.animation = 'none';
        setTimeout(function() {
            progressBar.style.animation = `progress-fill 10s 1 ease-in-out both`;
        }, 10)
    }

    getHeadingSpan(nameArr) {
        if(nameArr.length > 1) {
            return (
                nameArr.map((word) => {
                    return (
                        <div className='span-block' key={word} style={{overflow: 'hidden'}}>
                            {
                                word.split('').map((char, index) => <span key={char + '_span_' + index} style={!char.trim() ? { width: '12px' } : {}}>{char}</span>)
                            }
                        </div>
                    )
                })
            )
        }
        return (
            <div className='span-block' key={nameArr[0]} style={{overflow: 'hidden'}}>
                {
                    nameArr[0].split('').map((char, index) => <span key={char + '_span_' + index} style={!char.trim() ? { width: '12px' } : {}}>{char}</span>)
                }
            </div>
        )
    }

    render() {
        const {
            carouselData,
            currentSlide,
            totalSlide
        } = this.state;
        const {
            id
        } = this.props;
        return(
            <CarouselContainer
                id={id}
            >
                <div
                    className='section-left'
                    ref={(leftSectionRef) => {
                        this._leftSectionRef = leftSectionRef;
                    }}
                >
                    <div className='carousel-data-block'>
                        {carouselData.map((data) => {
                            return (
                                <div
                                    key={data.icon}
                                    className={data.className}
                                >
                                    <div className='icon-block'>
                                        <Icon
                                            name={data.icon}
                                            color='#ffffff'
                                            width={40}
                                            height={40}
                                        />
                                    </div>
                                    <div className='details-block'>
                                        <div className='heading'>
                                            {this.getHeadingSpan(data.name)}
                                        </div>
                                        <h2 className='sub-heading'>{data.subHeading}</h2>
                                        <p className='description'>{data.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='navigation-block'>
                        <div className='navigation'>
                            <div
                                role="presentation"
                                className='navigation-button button-left'
                                onClick={() => this.changeSlide('left', 'manual')}
                            >
                                <Icon
                                    name='arrow-left'
                                    color='#ffffff'
                                />
                            </div>
                            <div
                                role="presentation"
                                className='navigation-button button-right'
                                onClick={() => this.changeSlide('right', 'manual')}
                            >
                                <Icon
                                    name='arrow-right'
                                    color='#ffffff'
                                />
                            </div>
                        </div>
                        <div className='carousel-progress'>
                            <p className='slide-num current-slide'>{currentSlide}</p>
                            <div className='progress-bar-block'>
                                <div className='progress-bar'></div>
                            </div>
                            <p className='slide-num total-slide'>{totalSlide}</p>
                        </div>
                    </div>
                </div>
                <div
                    className='section-right'
                    ref={(rightSectionRef) => {
                        this._rightSectionRef = rightSectionRef;
                    }}
                >
                    {carouselData.map((data) => {
                            return (
                                <div
                                    key={data.icon}
                                    className={data.className + ' image-block'}
                                >
                                    <Img alt={data.icon} className='carousal-image' fluid={data.src.childImageSharp.fluid} />
                                </div>
                            )
                    })}
                </div>
            </CarouselContainer>
        )
    }
}

FullPageCarousel.propTypes = {
    dataArr: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string
}